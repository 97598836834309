import { Environment } from "@my/config/src/environment"
import { writeToDebug } from "@my/config/src/debug"

/**
 * Represents a callback function that handles WebSocket message events.
 *
 * @param details - The message payload received from the WebSocket connection.
 *                 This can contain any data type depending on the message format.
 *                 It's recommended to type this more specifically based on your message schema.
 * @returns void
 *
 * @example
 * const handleMessage: Callback = (details) => {
 *   console.log('Received message:', details)
 * }
 */
export type Callback = (details: any) => void

/**
 * Interface defining the WebSocket client functionality.
 * Provides methods for initializing, managing connections, and sending/receiving messages.
 */
export type Socket = {
  /**
   * Initializes the WebSocket connection with a token provider function.
   * @param tokenFn Function that returns a Promise resolving to an authentication token
   */
  init(tokenFn: () => Promise<string | undefined>): void

  /**
   * Updates the token provider function used for authentication.
   * @param tokenFn New function that returns a Promise resolving to an authentication token
   */
  setTokenFn(tokenFn: () => Promise<string | undefined>): void

  /**
   * Closes the WebSocket connection and cleans up resources.
   */
  close(): void

  /**
   * Attempts to reconnect the WebSocket.
   * @param force Whether to force reconnection even if already connected
   * @returns Promise that resolves when reconnection is complete
   */
  reconnect({ force }: { force: boolean }): Promise<void>

  /**
   * Registers callback(s) for specified message key(s).
   * @param keys Single key or array of keys to listen for
   * @param callback Function to execute when matching message is received
   */
  on(keys: string | string[], callback: Callback): void

  /**
   * Sends a message through the WebSocket connection.
   * @param data Message payload to send
   */
  emit(data: Record<string, string | number | boolean | null | undefined> | string): void
}

/** Status object returned by getStatus for testing purposes */
type SocketStatus = {
  client: WebSocket | undefined
  callbacks: Record<string, Callback>
  keeper: NodeJS.Timeout | undefined
}

/** Maximum number of retry attempts before giving up */
const MAX_RETRIES = 5 // Increased retries for better resilience

/** Initial delay in milliseconds between retry attempts */
const INITIAL_RETRY_DELAY = 1000

/** Maximum delay in milliseconds between retry attempts */
const MAX_RETRY_DELAY = 30000

/** Maximum number of retry attempts for failed message sends before dropping the message */
export const MAX_MESSAGE_RETRIES = 5

/** Timeout in milliseconds before a connection attempt is considered failed */
const CONNECTION_TIMEOUT = 10000

/**
 * Initial hash value for the DJB2 hashing algorithm.
 *
 * The DJB2 hash function is a simple but effective non-cryptographic hash function
 * created by Daniel J. Bernstein. It starts with this magic number (5381) which has
 * been found to work well in practice for string hashing.
 *
 * @constant
 * @type {number}
 * @default 5381
 */

const INITIAL_DJB2_HASH = 5381

export class SocketImpl implements Socket {
  private client: WebSocket | undefined
  private callbacks: Record<string, Callback> = {}
  private connectionKeeper: NodeJS.Timeout | undefined
  private tokenFn: (() => Promise<string | undefined>) | undefined
  /** Flag indicating whether the WebSocket is currently connected */
  private isConnected: boolean = false

  /** Promise that resolves when the connection is established */
  private connectionPromise: Promise<void> | null = null

  /** Queue to store messages that need to be sent when connection is restored */
  private messageQueue: Array<Record<string, any> | string> = []

  /** Counter for connection retry attempts */
  private retryCount: number = 0

  /** Flag indicating whether a reconnection attempt is in progress */
  private isReconnecting: boolean = false

  /**
   * Map to track the number of failed send attempts per message ID.
   * Key: messageId, Value: number of attempts
   */
  private readonly failedMessageAttempts: Map<string, number> = new Map()

  /** Flag indicating whether the socket has been closed */
  private isClosed: boolean = false

  /**
   * Unique log identifier for this instance of the SocketImpl. Useful for debugging multiple instances.
   */
  private readonly instanceId: string

  /**
   * Abort controller for the current connection attempt
   */
  private abortController: AbortController | null = null

  constructor() {
    // Generate a short unique ID for this instance
    this.instanceId = Math.random().toString(36).substring(2, 8)
  }

  /**
   * Gets the current connection status of the WebSocket.
   *
   * @returns {boolean} True if the WebSocket is currently connected, false otherwise
   */
  getIsConnected(): boolean {
    return this.isConnected
  }

  /**
   * Gets the current status of the WebSocket connection.
   * This method is primarily used for debugging and monitoring purposes.
   *
   * @returns {Object} An object containing:
   *   - client: The current WebSocket client instance
   *   - callbacks: Map of registered event callbacks
   *   - keeper: The connection keeper interval that maintains the connection
   *
   * @visibleForTesting
   * @internal
   */
  getStatus(): SocketStatus {
    return {
      client: this.client,
      callbacks: this.callbacks,
      keeper: this.connectionKeeper,
    }
  }

  /**
   * Initializes the WebSocket connection with authentication.
   *
   * This method:
   * 1. Sets up the token retrieval function for authentication
   * 2. Closes any existing connection
   * 3. Creates a new WebSocket client
   * 4. Starts the connection monitor
   * 5. Exposes the socket for external use
   *
   * @param {() => Promise<string | undefined>} tokenFn - Function that returns a Promise
   *        resolving to an authentication token or undefined if no token is available
   */
  init(tokenFn: () => Promise<string | undefined>) {
    writeToDebug(this.getLogTag(), "init")
    this.setTokenFn(tokenFn)
    this.close()
    this.createClient()
      .then(() => {
        this.monitorSocket()
        this.exposeSocket()
      })
      .catch((error) => {
        console.error(`catch{} ${error}`)
      })
  }

  /**
   * Sets the function used to retrieve authentication tokens.
   *
   * This method updates the token retrieval function used for WebSocket authentication.
   * The token function should return a Promise that resolves to either:
   * - A string containing the authentication token
   * - undefined if no token is available
   *
   * @param {() => Promise<string | undefined>} tokenFn - The function that returns a Promise resolving to an auth token
   */
  setTokenFn(tokenFn: () => Promise<string | undefined>) {
    this.tokenFn = tokenFn
  }

  private sendMessage(data: Record<string, any> | string) {
    if (this.isConnected && this.client?.readyState === WebSocket.OPEN) {
      const messageId = this.getMessageId(data)
      this.client.send(JSON.stringify(data))
      writeToDebug(this.getLogTag(), "Message sent:", data)
      this.failedMessageAttempts.delete(messageId)
    } else {
      writeToDebug(
        this.getLogTag(),
        "Cannot send message, socket not connected. Queueing message:",
        data,
      )
      this.messageQueue.push(data)
    }
  }

  /**
   * Sets up the WebSocket message handler to process incoming messages.
   *
   * This method configures the onmessage handler to:
   * - Parse incoming JSON messages
   * - Route messages to registered callbacks based on message type
   * - Handle parsing errors and invalid message formats
   * - Log debug information for message processing
   *
   * Messages are expected to be JSON objects with a 'type' field that maps
   * to registered callback handlers.
   */
  private setupMessageHandler() {
    if (!this.client) return

    this.client.onmessage = (event) => {
      try {
        let data
        try {
          data = JSON.parse(event.data)
        } catch (e) {
          writeToDebug(this.getLogTag(), "Received non-JSON message:", event.data)
          return
        }

        if (data && typeof data.type === "string") {
          const callback = this.callbacks[data.type]
          if (callback) {
            callback(data)
          } else {
            writeToDebug(this.getLogTag(), "No callback registered for message type:", data.type)
          }
        } else {
          writeToDebug(this.getLogTag(), "Received message without a type:", data)
        }
      } catch (error) {
        console.error("Error processing message:", error)
      }
    }
  }

  /**
   * Sets up the WebSocket event handlers for managing the connection lifecycle.
   *
   * @param connectionTimeout - The timeout handle used to detect connection failures
   * @param resolve - Callback function to resolve the connection promise when successfully connected
   * @param handleConnectionFailure - Callback function to handle connection failures with an error
   *
   * This method configures:
   * - onopen: Handles successful connections, clears timeouts, and processes queued messages
   * - onclose: Manages connection closure and initiates reconnection if needed
   * - onerror: Handles connection errors and triggers failure handling
   */
  private setupWebSocketHandlers(
    connectionTimeout: NodeJS.Timeout,
    resolve: () => void,
    reject: (error: Error) => void,
  ) {
    if (!this.client) return

    this.client.onopen = () => {
      clearTimeout(connectionTimeout)
      writeToDebug(
        this.getLogTag(),
        "WebSocket connected with readyState:",
        this.client?.readyState,
      )
      this.isConnected = true
      this.retryCount = 0
      this.flushMessageQueue()
      resolve()
    }

    this.client.onclose = (event) => {
      clearTimeout(connectionTimeout)
      writeToDebug(this.getLogTag(), "WebSocket closed:", event.reason)
      const wasConnected = this.isConnected
      this.isConnected = false
      this.client = undefined

      if (wasConnected) {
        this.handleReconnection(new Error("WebSocket closed unexpectedly"), true)
      } else {
        reject(new Error("Connection closed before established"))
      }
    }

    this.client.onerror = (event) => {
      clearTimeout(connectionTimeout)
      const errorMessage = typeof event === "string" ? event : "Unknown WebSocket error"
      console.error("WebSocket error:", errorMessage)
      this.client?.close()
      reject(new Error(`WebSocket connection failed: ${errorMessage}`))
    }
  }

  private handleConnectionFailure(
    error: Error,
    resolve: () => void,
    reject: (error: Error) => void,
  ) {
    if (this.retryCount < MAX_RETRIES) {
      this.retryCount++
      const delay = Math.min(
        INITIAL_RETRY_DELAY * Math.pow(2, this.retryCount - 1),
        MAX_RETRY_DELAY,
      )
      const jitter = Math.random() * 1000
      const totalDelay = delay + jitter

      writeToDebug(
        this.getLogTag(),
        `Retrying connection (${this.retryCount}/${MAX_RETRIES}) in ${totalDelay.toFixed(0)}ms...`,
      )
      setTimeout(() => {
        const connectionTimeout = setTimeout(() => {
          if (!this.isConnected && this.client?.readyState !== WebSocket.OPEN) {
            this.client?.close()
            reject(new Error("Connection timeout"))
          }
        }, CONNECTION_TIMEOUT)

        this.establishConnection(resolve, reject, connectionTimeout)
      }, totalDelay)
    } else {
      writeToDebug(this.getLogTag(), "Max retries reached. Failed to connect WebSocket:", error)
      reject(error)
    }
  }

  private establishConnection(
    resolve: () => void,
    reject: (error: Error) => void,
    connectionTimeout: NodeJS.Timeout,
  ) {
    try {
      this.isReconnecting = true

      if (!this.tokenFn) {
        throw new Error("Token function is not set")
      }

      this.tokenFn()
        .then((token) => {
          if (!token) {
            throw new Error("No token available")
          }

          this.client = new WebSocket(`${Environment.SERVER_WS_URL}/ws/${token}`)

          this.setupWebSocketHandlers(connectionTimeout, resolve, (error) =>
            this.handleConnectionFailure(error, resolve, reject),
          )
          this.setupMessageHandler()
          // On successful connection, reset retry count
          this.retryCount = 0
        })
        .catch((error) => {
          console.error(`catch{} ${error}`)
          writeToDebug(this.getLogTag(), "Failed to establish connection:", error)
          reject(error)
        })
    } catch (error) {
      console.error(`catch{} ${error}`)
      writeToDebug(this.getLogTag(), "Failed to establish connection:", error)
      reject(error as Error)
    } finally {
      this.isReconnecting = false
    }
  }

  private async createClient(): Promise<void> {
    writeToDebug(
      this.getLogTag(),
      "createClient called. Existing promise:",
      !!this.connectionPromise,
    )
    if (this.connectionPromise) {
      writeToDebug(this.getLogTag(), "Returning existing connection promise")
      return this.connectionPromise
    }

    // Create new AbortController for this connection attempt
    this.abortController = new AbortController()
    let connectionTimeout: NodeJS.Timeout

    // Create a new connection promise without async executor
    this.connectionPromise = new Promise<void>((resolve, reject) => {
      // Add signal handler to reject if aborted
      this.abortController?.signal.addEventListener("abort", () => {
        clearTimeout(connectionTimeout)
        writeToDebug(this.getLogTag(), "ACK: Connection aborted")
        reject(new Error("Connection aborted"))
      })

      connectionTimeout = setTimeout(() => {
        if (!this.isConnected && this.client?.readyState !== WebSocket.OPEN) {
          writeToDebug(this.getLogTag(), "Connection attempt timed out")
          this.client?.close()
          reject(new Error("Connection timeout"))
        }
      }, CONNECTION_TIMEOUT)

      this.establishConnection(resolve, reject, connectionTimeout)
    })

    try {
      writeToDebug(this.getLogTag(), "Awaiting connection promise")
      await this.connectionPromise
      writeToDebug(this.getLogTag(), "Connection promise resolved successfully")
    } catch (error) {
      if (!this.isClosed) {
        writeToDebug(this.getLogTag(), "Connection promise rejected:", error)
        console.error("Failed to create client:", error)
      }
    } finally {
      this.connectionPromise = null
      this.abortController = null
      writeToDebug(this.getLogTag(), "Connection promise cleared")
    }
  }

  /**
   * Handles failed WebSocket message delivery attempts.
   *
   * This method implements an exponential backoff retry mechanism for failed messages:
   * - Tracks the number of retry attempts for each message
   * - Drops messages that exceed MAX_MESSAGE_RETRIES
   * - Requeues failed messages with exponential delay between attempts
   * - Uses message ID to track retry counts across attempts
   *
   * @param message - The failed message to handle (either string or object)
   * @param error - The error that occurred during message sending
   */
  private handleFailedMessage(message: Record<string, any> | string, error: unknown) {
    const messageId = this.getMessageId(message)
    const previousAttempts = this.failedMessageAttempts.get(messageId) ?? 0
    const currentAttempts = previousAttempts + 1

    writeToDebug(this.getLogTag(), "Failed to send queued message:", error)

    if (currentAttempts >= MAX_MESSAGE_RETRIES) {
      this.failedMessageAttempts.delete(messageId)
      writeToDebug(this.getLogTag(), "Message exceeded max retries, will be dropped:", message)
      return
    }

    // Update the attempt count
    this.failedMessageAttempts.set(messageId, currentAttempts)

    // Put the message back in queue with a delay using exponential backoff
    const delay = Math.min(1000 * Math.pow(2, currentAttempts - 1), 30000) // Cap at 30 seconds
    const jitter = Math.random() * 1000
    const totalDelay = delay + jitter
    setTimeout(() => {
      this.messageQueue.unshift(message)
      writeToDebug(this.getLogTag(), `Requeued message after ${totalDelay}ms delay.`)
      this.flushMessageQueue()
    }, totalDelay)
  }

  /**
   * Checks if the WebSocket connection is ready to process messages from the queue.
   *
   * This method verifies three conditions:
   * 1. There are messages in the queue to be processed
   * 2. The socket is marked as connected
   * 3. The WebSocket client is in OPEN state and ready for communication
   *
   * @returns {boolean} True if messages can be processed, false otherwise
   */
  private canProcessMessages(): boolean {
    return (
      this.messageQueue.length > 0 && this.isConnected && this.client?.readyState === WebSocket.OPEN
    )
  }

  /**
   * Processes and sends all messages currently in the message queue.
   *
   * This method:
   * - Continuously checks if messages can be processed using canProcessMessages()
   * - Takes messages from the front of the queue and attempts to send them
   * - Handles any failures through handleFailedMessage()
   * - Stops processing if any message send fails to prevent overwhelming the socket
   */
  private flushMessageQueue() {
    let processed = 0
    const maxIterations = this.messageQueue.length

    while (this.canProcessMessages() && processed < maxIterations) {
      const message = this.messageQueue.shift()
      if (!message) continue

      try {
        this.sendMessage(message)
      } catch (error) {
        this.handleFailedMessage(message, error)
        break
      }

      processed++
    }
  }

  /**
   * Monitors the WebSocket connection status and handles automatic reconnection.
   *
   * This method:
   * - Sets up an interval check every 30 seconds to monitor socket health
   * - Detects if the socket is in CLOSED or CLOSING state
   * - Attempts to reconnect automatically if the socket is not healthy
   * - Ensures only one monitor is running by tracking the connectionKeeper
   *
   * The monitor continues running until explicitly stopped via the close() method.
   */
  private monitorSocket() {
    if (this.connectionKeeper) {
      return // Monitor already running
    }

    if (!this.isClosed) {
      this.connectionKeeper = setInterval(() => {
        if (
          this.client?.readyState === WebSocket.CLOSED ||
          this.client?.readyState === WebSocket.CLOSING
        ) {
          writeToDebug(this.getLogTag(), "Detected closed socket. Attempting to reconnect...")
          this.reconnect({ force: false }).catch((error) => {
            writeToDebug(this.getLogTag(), "Reconnection attempt failed:", error)
          })
        }
      }, 30000)
      writeToDebug(this.getLogTag(), "WebSocket monitor started.")
    }
  }

  /**
   * Closes the WebSocket connection and cleans up all associated resources.
   *
   * This method:
   * - Stops the connection monitoring interval
   * - Closes the WebSocket connection if active
   * - Resets all internal state variables to their default values
   * - Detaches any socket event listeners
   *
   * After calling this method, the socket instance will need to be reconnected
   * before it can be used again for communication.
   */
  close() {
    writeToDebug(this.getLogTag(), "Socket close called. Connected:", this.isConnected)
    this.isClosed = true

    // Abort any pending connection attempt
    if (this.abortController) {
      writeToDebug(this.getLogTag(), "Aborting connection attempt")
      this.abortController.abort()
      this.abortController = null
    }

    // Detach socket before cleaning up other resources
    this.detachSocket()

    if (this.connectionKeeper) {
      clearInterval(this.connectionKeeper)
      this.connectionKeeper = undefined
      writeToDebug(this.getLogTag(), "WebSocket monitor stopped.")
    }

    if (this.client) {
      if (
        this.client.readyState === WebSocket.OPEN ||
        this.client.readyState === WebSocket.CONNECTING
      ) {
        this.client.close()
        writeToDebug(this.getLogTag(), "WebSocket connection closed.")
      }

      // Conditionally nullify event handlers only if not in a test environment
      if (process.env.NODE_ENV !== "test") {
        this.client.onopen = null
        this.client.onclose = null
        this.client.onerror = null
        this.client.onmessage = null
      }
      this.client = undefined
    }

    this.isConnected = false
    this.connectionPromise = null
    this.messageQueue = []
    this.retryCount = 0
    this.isReconnecting = false
    this.failedMessageAttempts.clear()
  }

  /**
   * Attempts to reconnect the WebSocket connection.
   *
   * This method handles the reconnection logic by:
   * 1. Checking if a reconnection is already in progress
   * 2. Closing the existing connection if forced or if not in OPEN state
   * 3. Creating a new WebSocket client
   * 4. Restarting the connection monitor
   *
   * @param {Object} params - The reconnection parameters
   * @param {boolean} params.force - If true, forces a reconnection even if the socket is open
   * @returns {Promise<void>} A promise that resolves when reconnection is complete
   * @throws {Error} If the reconnection attempt fails
   */
  async reconnect({ force }: { force: boolean }): Promise<void> {
    if (this.isReconnecting) {
      writeToDebug(this.getLogTag(), "Reconnection already in progress.")
      return
    }

    const shouldReconnect = force || (this.client && this.client.readyState !== WebSocket.OPEN)

    if (shouldReconnect) {
      writeToDebug(this.getLogTag(), "Reconnecting WebSocket...")
      // Store reference to current socket before closing
      const previousSocket = this.client
      this.close()

      // Wait for previous socket to fully close if it exists
      if (previousSocket) {
        await new Promise<void>((resolve) => {
          if (previousSocket.readyState === WebSocket.CLOSED) {
            resolve()
          } else {
            previousSocket.onclose = () => resolve()
          }
        })
      }

      try {
        await this.createClient()
        this.monitorSocket()
        writeToDebug(this.getLogTag(), "Reconnected WebSocket successfully.")
      } catch (error) {
        writeToDebug(this.getLogTag(), "Failed to reconnect WebSocket:", error)
        throw error
      }
    }
  }

  /**
   * Handles the WebSocket reconnection process when a connection error occurs.
   *
   * This method manages the reconnection state and attempts to reestablish the connection by:
   * 1. Checking if a reconnection is already in progress to prevent multiple attempts
   * 2. Setting the reconnection flag
   * 3. Initiating a non-forced reconnection attempt
   * 4. Handling any errors during reconnection
   * 5. Resetting the reconnection flag when complete
   *
   * @param {Error} error - The error that triggered the reconnection attempt
   */
  private handleReconnection(error: Error, force: boolean = false) {
    writeToDebug(this.getLogTag(), "Initiating reconnection due to error:", error)

    this.reconnect({ force }).catch((err) => {
      writeToDebug(this.getLogTag(), "Reconnection failed:", err)
    })
  }

  /**
   * Registers a callback function to be executed when messages with specific keys are received.
   *
   * This method allows subscribing to WebSocket messages by registering callback functions
   * that will be triggered when messages with matching keys are received. It supports both
   * single key and multiple key subscriptions.
   *
   * @param {string | string[]} keys - A single key or array of keys to subscribe to
   * @param {Callback} callback - The callback function to execute when a matching message is received
   */
  on(keys: string | string[], callback: Callback) {
    if (typeof keys === "string") {
      this.callbacks[keys] = callback
    } else {
      keys.forEach((key) => {
        this.callbacks[key] = callback
      })
    }
    writeToDebug(this.getLogTag(), "Registered callback for keys:", keys)
  }

  /**
   * Emits data through the WebSocket connection.
   *
   * This method handles sending data through the WebSocket, with built-in queue management
   * for when the connection is not available. It will:
   * 1. Send immediately if connected
   * 2. Queue the message if disconnected
   * 3. Wait for any ongoing connection attempts before sending
   *
   * @param {Record<string, string | number | boolean | null | undefined> | string} data -
   *        The data to emit. Can be either a string or an object with primitive values
   * @returns {Promise<void>} A promise that resolves when the emit attempt is complete
   */
  async emit(data: Record<string, string | number | boolean | null | undefined> | string) {
    if (this.isConnected && this.client?.readyState === WebSocket.OPEN) {
      // If connected, send the message immediately
      this.sendMessage(data)
    } else {
      // Queue the message
      this.sendMessage(data)

      // Check if a connection attempt is already in progress
      if (!this.connectionPromise) {
        // No connection attempt in progress, throw an error
        throw new Error("Socket failed to connect after queuing message")
      }

      // Await the ongoing connection attempt without sending the message again
      try {
        await this.connectionPromise
        // After reconnection, the message queue will be flushed automatically
      } catch (error) {
        writeToDebug(this.getLogTag(), "Failed to emit message after connection:", error)
        throw error
      }
    }
  }

  /**
   * Exposes the socket instance to the window object for testing purposes.
   * @visibleForTesting
   */
  private exposeSocket() {
    if (
      typeof window !== "undefined" &&
      ["test", "development"].includes(process.env.NODE_ENV ?? "")
    ) {
      if (!this.isClosed) {
        // @ts-expect-error - Socket type is defined in e2e test types
        window.socket = {
          isConnected: this.getIsConnected.bind(this),
        }
        writeToDebug(this.getLogTag(), "WebSocket instance exposed on window.socket.")
      }
    }
  }

  /**
   * Detaches the socket instance from the window object.
   * @visibleForTesting
   */
  private detachSocket() {
    if (
      typeof window !== "undefined" &&
      ["test", "development"].includes(process.env.NODE_ENV ?? "")
    ) {
      delete (window as any).socket
      writeToDebug(this.getLogTag(), "WebSocket instance detached from window.socket.")
    }
  }

  /**
   * Generates a unique identifier for a message using UUID.
   *
   * @returns A string containing the UUID.
   * @visibleForTesting
   * @internal
   */
  getMessageId(message: Record<string, any> | string): string {
    // Create a string that represents the message content
    const contentString = typeof message === "string" ? message : JSON.stringify(message)

    // djb2 hash algorithm
    const hash = contentString.split("").reduce((hash, char) => {
      // `(hash << 5) - hash` is equivalent to `hash * 33`
      return ((hash << 5) - hash + char.charCodeAt(0)) | 0
    }, INITIAL_DJB2_HASH)

    // Convert hash to base36 for shorter strings
    return Math.abs(hash).toString(36)
  }

  /**
   * Returns a unique log tag for this instance of the SocketImpl.
   */
  getLogTag() {
    return `(socket::${this.instanceId})`
  }
}
