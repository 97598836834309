import { useFlags } from "launchdarkly-react-client-sdk"
import { useStoredBoolean } from "../storage"

export function useFeatureFlag<T extends string | boolean | number>(
  key: string,
  defaultValue: T,
): T {
  // Get the remote flag value from LaunchDarkly
  const flags = useFlags()
  const remoteValue = flags[key] ?? defaultValue

  // For boolean flags, check for local overrides
  if (typeof defaultValue === "boolean") {
    // Pass the remote value as the default for useStoredBoolean
    const [storedValue] = useStoredBoolean(`feature-flag-${key}`, remoteValue as boolean)
    return storedValue as T
  }

  // For non-boolean flags, use the remote value
  return remoteValue
}
