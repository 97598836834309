export default {
  primary: "#306C75",
  secondary: "#FCE7CB",
  tertiary: "#7ABDBA",
  action: "#FABD00",
  info: "#3F83C6",
  success: "#46CB86",
  warning: "#FF9500",
  error: "#D34242",
  superDark: "#22274E",
}
