/**
 * Collection of commonly used regular expressions.
 */
export const RegEx = {
  /**
   * Matches sequences of one or more whitespace characters or specified punctuation characters.
   *
   * This regex is useful for splitting strings into tokens based on whitespace and punctuation.
   *
   * - `\s+`: Matches one or more whitespace characters (spaces, tabs, newlines).
   * - `[!()*'"`@#\-_\u2013\u2014:.,?$%&[\]{}|<>~]+`: Matches one or more of the specified punctuation and special characters.
   *
   * Examples:
   * - "Hello, world! This is a test." => ["Hello", "world", "This", "is", "a", "test"]
   * - "Split!@this*string" => ["Split", "this", "string"]
   *
   * @type {RegExp}
   */
  tokenSplitter: /(\s+|[!()*'"`@#\-_\u2013\u2014:.,?$%&[\]{}|<>~]+)/,

  /**
   * Matches a string that consists entirely of whitespace and specified punctuation characters.
   *
   * This regex is useful for validating if a string contains only whitespace and punctuation.
   *
   * - `^`: Anchors the match to the beginning of the string.
   * - `[\s!()*'"`@#\-_\u2013\u2014:.,?$%&[\]{}|<>~]+`: Matches one or more of the specified whitespace and punctuation characters.
   *   - `\s`: Matches any whitespace character (spaces, tabs, newlines).
   *   - `!()*'"`@#\-_\u2013\u2014:.,?$%&[\]{}|<>~`: Matches any of the specified punctuation and special characters.
   * - `$`: Anchors the match to the end of the string.
   *
   * Examples:
   * - "!!!" => matches
   * - "  " => matches
   * - "Hello!" => does not match
   * - "1234" => does not match
   *
   * @type {RegExp}
   */
  nonAlphanumeric: /^[\s!()*'"`@#\-_\u2013\u2014:.,?$%&[\]{}|<>~]+$/,

  /**
   * Matches an ISO 8601 date string.
   *
   * This regex is useful for validating and parsing ISO 8601 date strings, which are commonly used in APIs and data interchange formats.
   *
   * - `^`: Anchors the match to the beginning of the string.
   * - `(\d{4})`: Matches a four-digit year.
   * - `-(\d{2})`: Matches a hyphen followed by a two-digit month.
   * - `-(\d{2})`: Matches a hyphen followed by a two-digit day.
   * - `T`: Matches the literal character "T".
   * - `(\d{2})`: Matches a two-digit hour.
   * - `:(\d{2})`: Matches a colon followed by a two-digit minute.
   * - `:(\d{2}(?:\.\d*)?)`: Matches a colon followed by a two-digit second and optional fractional seconds.
   * - `(?:Z|(\+|-)([\d|:]*))?$`: Matches either "Z" (for UTC) or a timezone offset (+ or -) followed by hours and optional minutes.
   * - `$`: Anchors the match to the end of the string.
   *
   * Examples:
   * - "2023-07-24T12:34:56Z" => matches
   * - "2023-07-24T12:34:56.789+01:00" => matches
   * - "2023-07-24" => does not match
   *
   * @type {RegExp}
   */
  isoDate: /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)(?:Z|(\+|-)([\d|:]*))?$/,

  /**
   * Matches a floating-point number.
   *
   * This regex is useful for validating and parsing floating-point numbers, including optional leading minus sign and optional decimal part.
   *
   * - `^-?`: Matches an optional leading minus sign.
   * - `\d*`: Matches zero or more digits.
   * - `(\.\d*)?$`: Matches an optional decimal point followed by zero or more digits, anchoring the match to the end of the string.
   *
   * Examples:
   * - "123.45" => matches
   * - "-123.45" => matches
   * - "123" => matches
   * - "-.45" => matches
   * - "abc" => does not match
   *
   * @type {RegExp}
   */
  float: /^-?\d*(\.\d*)?$/,

  /**
   * Matches a positive floating-point number.
   *
   * This regex is useful for validating and parsing positive floating-point numbers, including decimal values.
   * It matches strings containing only digits and optionally one decimal point.
   *
   * - `^`: Anchors the match to the beginning of the string.
   * - `\d*`: Matches zero or more digits.
   * - `\.?`: Matches an optional decimal point.
   * - `\d*`: Matches zero or more digits.
   * - `$`: Anchors the match to the end of the string.
   *
   * Examples:
   * - "123.45" => matches
   * - "0.45" => matches
   * - ".45" => matches
   * - "" => matches (allows empty string for input clearing)
   * - "-123.45" => does not match
   * - "abc" => does not match
   * - "1.2.3" => does not match
   *
   * @type {RegExp}
   */
  positiveFloat: /^\d*\.?\d*$/,

  /**
   * Matches an integer.
   *
   * This regex is useful for validating and parsing integers, including optional leading minus sign.
   *
   * - `^-?`: Matches an optional leading minus sign.
   * - `\d*$`: Matches zero or more digits, anchoring the match to the end of the string.
   *
   * Examples:
   * - "123" => matches
   * - "-123" => matches
   * - "0" => matches
   * - "-0" => matches
   * - "123.45" => does not match
   * - "abc" => does not match
   *
   * @type {RegExp}
   */
  int: /^-?\d*$/,
}
